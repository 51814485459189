import type { CompanyInsuranceRequestPossibleAction } from "../components/company-insurance-request/types.ts";
import type { DefinitiveEndStatus } from "../components/definitive-end-request/types.ts";
import type { MedicalCertificateStatus } from "../components/medical-certificate/types.ts";
import type { OnboardingStatus } from "../components/onboarding/types.ts";
import type { PlanningAction, PlanningStatus } from "../components/planning/types.ts";
import type { ProfessionalCardStatus } from "../components/professional-card/types.ts";
import type { ResidencePermitStatus } from "../components/residence-permit/types.ts";

import type {
  AcquisitionSource,
  AdvancePaymentRequestStatus,
  AmendmentType,
  ApiCaller,
  AppRole,
  ApplicationStage,
  CommercialConditionStatus,
  CommercialConditionType,
  CompanyInsuranceRequestStatus,
  ConstructionSiteSize,
  ConstructionType,
  ContractDocumentType,
  ContractReason,
  ContractStatus,
  ContractType,
  DayOfWeek,
  DefinitiveEndType,
  DocumentType,
  EmployeeStatus,
  ExpenseClaimRequestType,
  ExperienceLevel,
  Gender,
  IndependentPaymentRequestStatus,
  JobPostingStatus,
  JobRisk,
  MedicalCenterStatus,
  MedicalCheckupRequestStatus,
  MissionStatus,
  MissionType,
  Month,
  OnboardingRequestStatus,
  Ppe,
  PpeRequestStatus,
  QualificationCenterStatus,
  QualificationCenterTrainingType,
  QualificationTrainingRequestDocumentType,
  QualificationTrainingRequestStatus,
  RecommandationLevel,
  RecruitmentOfficeInvoiceRequestStatus,
  ReferenceCheckStatus,
  RequestStatus,
  RequestWithValidationStatus,
  SoftwareMasteryLevel,
  TalentAvailabilityStatus,
  TalentQualificationStatus,
  TeamSize,
  TimesheetWeekStatus,
  WorkAccidentRequestStatus,
} from "./supabase.enum.ts";

enum Locale {
  Fr = "fr",
}

export const translateContractType: {
  [key in Locale]: { [key in ContractType]: string };
} = {
  fr: {
    interim: "Intérim",
    temporary: "CDD",
    permanent: "CDI",
  },
};

export const translateMissionType: {
  [key in Locale]: { [key in MissionType]: string };
} = {
  fr: {
    works: "travaux",
    design_office_conception: "étude de conception",
    design_office_execution: "étude d'éxecution",
  },
};

export const translateConstructionType: {
  [key in Locale]: { [key in ConstructionType]: string };
} = {
  fr: {
    new_build: "neuf",
    rehabilitation: "réhabilitation",
    renovation: "rénovation",
    maintenance: "maintenance",
  },
};

export const translateApplicationStage: {
  [key in Locale]: { [key in ApplicationStage]: string };
} = {
  fr: {
    inbox: "Arrivé depuis la multidiffusion",
    shortlist: "Profil shortlisté",
    ready_to_submit: "Prêt à envoyer",
    client_review: "CV envoyé au client",
    interview: "En entretien",
    accepted: "Accepté",
  },
};

export const translateExperienceLevel: {
  [key in Locale]: { [key in ExperienceLevel]: string };
} = {
  fr: {
    from_0_to_1_year: "de 0 à 1 an",
    from_1_to_3_years: "de 1 à 3 ans",
    from_3_to_6_years: "de 3 à 6 ans",
    from_6_to_10_years: "de 6 à 10 ans",
    more_than_10_years: "plus de 10 ans",
  },
};

export const translateSoftwareMasteryLevel: {
  [key in Locale]: { [key in SoftwareMasteryLevel]: string };
} = {
  fr: {
    beginner: "novice",
    junior: "en formation",
    confirmed: "autonome",
    senior: "formateur",
    expert: "expert",
  },
};

export const translateSoftwareMasteryLevelDescription: {
  [key in Locale]: { [key in SoftwareMasteryLevel]: string };
} = {
  fr: {
    beginner: "maîtrise 0% à 10% des fonctionnalités",
    junior: "maîtrise 10% à 30% des fonctionnalités",
    confirmed: "maîtrise 30% à 60% des fonctionnalités",
    senior: "maîtrise 60% à 90% des fonctionnalités",
    expert: "maîtrise plus de 90% des fonctionnalités",
  },
};

export const translateTeamSize: {
  [key in Locale]: { [key in TeamSize]: string };
} = {
  fr: {
    less_than_10_people: "moins de 10 personnes",
    from_10_to_20_people: "de 10 à 20 personnes",
    from_20_to_50_people: "de 20 à 50 personnes",
    more_than_50_people: "plus de 50 personnes",
  },
};

export const translateConstructionSiteSize: {
  [key in Locale]: { [key in ConstructionSiteSize]: string };
} = {
  fr: {
    less_than_100K: "moins de 100k€",
    from_100K_to_500K: "de 100k à 500k€",
    from_500K_to_1M: "de 500k à 1M€",
    from_1M_to_10M: "de 1M à 10M€",
    more_than_10M: "plus de 10M€",
  },
};

export const translateGenderType: {
  [key in Locale]: { [keyof in Gender]: string };
} = {
  fr: {
    female: "Femme",
    male: "Homme",
    neutral: "Neutre",
  },
} as const;

export const translateReferenceCheckStatus: {
  [key in Locale]: { [key in ReferenceCheckStatus]: string };
} = {
  fr: {
    old: "⚪️ Non renseignée",
    todo: "🔴 À traiter",
    done: "🟢 Effectuée",
    canceled: "⚫️ Annulée",
  },
};

export const translateRequestWithValidationStatus: {
  [key in Locale]: { [key in RequestWithValidationStatus]: string };
} = {
  fr: {
    to_validate: "À valider",
    approved: "Approuvé",
    pending: "À traiter",
    canceled: "Annulé",
    refused: "Refusé",
  },
};

export const translateRequestStatus: {
  [key in Locale]: { [key in RequestStatus]: string };
} = {
  fr: {
    approved: "Approuvé",
    pending: "À traiter",
    canceled: "Annulé",
    refused: "Refusé",
  },
};

export const translateRecruitmentOfficeInvoiceRequestStatus: {
  [key in Locale]: { [key in RecruitmentOfficeInvoiceRequestStatus]: string };
} = {
  fr: {
    new: "Nouveau",
    pending: "Brouillon à valider",
    approved: "Approuvé",
    refused: "Refusé",
    done: "Traité",
    canceled: "Annulé",
  },
};

export const translateMedicalCheckupRequestStatus: {
  [key in Locale]: { [key in MedicalCheckupRequestStatus]: string };
} = {
  fr: {
    todo: "À traiter",
    pending: "En cours",
    approved: "Convoqué",
    done: "Terminé",
    canceled: "Annulé",
  },
};

export const translateMedicalCertificateStatus: {
  [key in Locale]: { [key in MedicalCertificateStatus]: string };
} = {
  fr: {
    todo: "En cours de traitement",
    pending: "En cours de traitement",
    approved: "Convoqué",
    done: "Terminé",
    canceled: "Annulé",
    valid: "Validé",
    expired: "Expiré",
    expired_soon: "Expire bientôt",
    expiration_undefined: "Date d'expiration non définie",
    document_missing: "Document manquant",
    request_to_submit: "Demande à réaliser",
  },
};

export const translateProfessionalCardStatus: {
  [key in Locale]: { [key in ProfessionalCardStatus]: string };
} = {
  fr: {
    pending: "En cours de traitement",
    approved: "Traitée",
    refused: "Refusé",
    canceled: "Annulé",
    valid: "Valide",
    expired: "Expiré",
    expired_soon: "Expire bientôt",
    expiration_undefined: "Date d'expiration non définie",
    document_missing: "Document manquant",
    request_to_submit: "Demande à réaliser",
  },
};

export const translateQualificationTrainingRequestStatus: {
  [key in Locale]: { [key in QualificationTrainingRequestStatus]: string };
} = {
  fr: {
    todo: "À traiter",
    pending: "En cours",
    approved: "Convoqué",
    done: "Terminé",
    canceled: "Annulé",
  },
};

export const translateQualificationTrainingRequestDocumentType: {
  [key in Locale]: { [key in QualificationTrainingRequestDocumentType]: string };
} = {
  fr: {
    invoicing: "Documents de facturation",
    convocation: "Convocation",
    certificate: "Certificat",
    other: "Autres documents",
  },
};

export const translateContractStatus: {
  [key in Locale]: { [key in ContractStatus]: string };
} = {
  fr: {
    draft: "Brouillon",
    to_process: "À traiter",
    to_send: "À envoyer",
    waiting_for_signature: "En signature",
    signed: "Signé",
    cancelled: "Annulé",
    to_validate: "À valider",
  },
};

export const translateMedicalCenterStatus: {
  [key in Locale]: { [key in MedicalCenterStatus]: string };
} = {
  fr: {
    pending: "En cours",
    approved: "Validé",
    refused: "Refusé",
  },
};

export const translateMedicalCenterOption: {
  [key in Locale]: { [key in Exclude<MedicalCenterStatus, "pending">]: string };
} = {
  fr: {
    approved: "✅ Valider",
    refused: "❌ Refuser",
  },
};

export const translateQualificationCenterOption: {
  [key in Locale]: { [key in Exclude<QualificationCenterStatus, "pending">]: string };
} = {
  fr: {
    approved: "✅ Valider",
    refused: "❌ Refuser",
  },
};
export const translateQualificationCenterStatus: {
  [key in Locale]: { [key in QualificationCenterStatus]: string };
} = {
  fr: {
    pending: "En cours",
    approved: "Validé",
    refused: "Refusé",
  },
};

export const translateQualificationCenterTrainingType: {
  [key in Locale]: { [key in QualificationCenterTrainingType]: string };
} = {
  fr: {
    remote: "Remote",
    "in-person": "Présentiel",
    hybrid: "Hybride",
  },
};
export const translateAdvancePaymentRequestStatus: {
  [key in Locale]: { [key in AdvancePaymentRequestStatus]: string };
} = {
  fr: {
    approved: "Traité",
    paid: "Déjà payé",
    pending: "À traiter",
    canceled: "Annulé",
    refused: "Refusé",
  },
};

export const translateIndependentPaymentRequestStatus: {
  [key in Locale]: { [key in IndependentPaymentRequestStatus]: string };
} = {
  fr: {
    pending: "À traiter",
    approved: "Approuvé",
    refused: "Refusé",
    paid: "Payé",
    canceled: "Annulé",
  },
};

export const translateJobRisk: {
  [key in Locale]: { [key in JobRisk]: string };
} = {
  fr: {
    burial_or_sinking: "Risques de noyade ou ensevelissement",
    dust_treatments_milling: "Poussières, traitements, fraisage",
    fall_from_height: "Chute de hauteur",
    handling_heavy_objects: "Manutention d'objets lourds",
    manual_handling: "Manutention manuelle",
    demolition: "Risques de démolition",
    electrical_risks: "Risques électriques",
    use_of_chemical_products: "Risques chimiques",
    use_of_dangerous_tools: "Utilisation d'outils dangereux",
    working_under_traffic: "Travail sur voie de circulation",
  },
};

export const translateEmployeeStatus: {
  [key in Locale]: { [key in EmployeeStatus]: string };
} = {
  fr: {
    executive: "Cadre",
    technician: "ETAM",
    worker: "Ouvrier",
  },
};

export const translateContractReason: {
  [key in Locale]: { [key in ContractReason]: string };
} = {
  fr: {
    increased_company_actvity: "Accroissement temporaire d'activité",
    employee_replacement: "Remplacement d’un employé",
    talent_professional_training: "Formation professionnelle de l’intérimaire",
  },
};

export const translatePpe: {
  [key in Locale]: { [key in Ppe]: string };
} = {
  fr: {
    safety_shoes: "Chaussures de sécurité",
    hearing_protection_helmet: "Casque anti-bruit",
    protective_glasses: "Lunettes de protection",
    protective_gloves: "Gants de protection",
    safety_helmet: "Casque de sécurité",
    safety_vest: "Gilet de sécurité",
    safety_pants: "Pantalon de sécurité",
  },
};

export const translateMonth: {
  [key in Locale]: { [key in Month]: string };
} = {
  fr: {
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
  },
};

export type SinceOptions = "one_month" | "three_months" | "six_months" | "forever";

export const translateTimesheetSinceOption: {
  [key in Locale]: { [key in SinceOptions]: string };
} = {
  fr: {
    one_month: "1 mois",
    three_months: "3 mois",
    six_months: "6 mois",
    forever: "toujours",
  },
};

export const translateDayOfWeek = function (day: DayOfWeek) {
  const map: { [key in DayOfWeek]: string } = {
    monday: "lundi",
    tuesday: "mardi",
    wednesday: "mercredi",
    thursday: "jeudi",
    friday: "vendredi",
    saturday: "samedi",
    sunday: "dimanche",
  };

  return map[day];
};

export const translateAmendmentType: {
  [key in Locale]: { [key in AmendmentType]: string };
} = {
  fr: {
    extension: "prolongation",
    modification: "modificatif",
  },
};

export const translateDocumentType: {
  [key in Locale]: { [key in DocumentType]: string };
} = {
  fr: {
    admin__banking__rib: "RIB",
    admin__health__medical_certificate: "Certificat médical",
    admin__health__medical_convocation: "Convocation médicale",
    professional__reference_letter: "Lettre de recommandation",
    professional__qualification_certificate: "Habilitation",
    professional__professional_card_btp: "Carte BTP",
    professional__driving_licence: "Permis de conduire",
    professional__cover_letter: "Lettre de motivation",
    other__misc: "Autre",
    other__legal__criminal_record: "Casier judiciaire",
    other__company_insurance__response: "Attestation d'assurance",
    admin__qualification_training_request: "Demande d'habilitation",
    admin__residence__proof_of_identity_of_the_host: "Justificatif d'identité de l'hébergeur",
    admin__residence__proof_of_address_of_the_host: "Justificatif de domicile de l'hébergeur",
    admin__residence__proof_of_address: "Justificatif de domicile",
    admin__residence__accomodation_certificate: "Attestation d'hébergement",
    admin__identity__residence_permit_receipt: "Récépissé de titre de séjour",
    admin__identity__residence_permit: "Titre de séjour",
    admin__identity__passeport: "Passeport",
    admin__identity__id_card: "Carte d'identité",
    admin__health_insurance__vital_card: "Carte vitale",
    admin__health_insurance__social_security_certificate: "Attestation de sécurité sociale",
    admin__health_insurance__european_health_insurance_card: "Carte européenne d'assurance maladie",
    contract__recruitment_office__invoice_draft: "Brouillon de facture du cabinet de recrutement",
    contract__recruitment_office__invoice_real: "Facture finale du cabinet de recrutement",
    admin__contract__document: "Document lié au contrat",
    admin__health__work_accident_work_interruption_certificate: "Certificat d'arrêt de travail",
    admin__health__work_accident_submission_certificate: "Attestation de dépôt de dossier d'accident du travail",
  },
};

export const translateMissionJobPostingStatus: {
  [key in Locale]: { [key in JobPostingStatus]: string };
} = {
  fr: {
    archived: "Archivé",
    draft: "Brouillon",
    published: "Publié",
  },
};

export const translateCommercialConditionType: {
  [key in Locale]: { [key in CommercialConditionType]: string };
} = {
  fr: {
    interim: "Intérim",
    recruitment_office: "Cabinet de recrutement",
    interim_and_recruitment_office: "Intérim et cabinet de recrutement",
  },
};

export const translateDefinitiveEndType: {
  [key in Locale]: { [key in DefinitiveEndType]: string };
} = {
  fr: {
    sick_leave: "Arrêt Maladie",
    found_permanent_job_elsewhere: "A trouvé un CDI ailleurs",
    technical_bug: "Bug technique",
    worker_leave: "Congés intérimaire",
    permanent_hire: "Embauche CDI - autre client d'asap.work",
    permanent_hire_by_current_client: "Embauche CDI par le client actuel",
    end_of_mission_by_worker: "FDM à l’initiative de l’intérimaire",
    end_of_mission: "Fin de chantier",
    competition_flip_flop: "Flipflop par la concurrence",
    not_qualified: "Pas au niveau",
    no_show: "Plantage",
    talent_no_show: "Plantage par le candidat",
    client_no_show: "Plantage Client",
    contract_renewal: "Renouvellement de contrat",
    negative_flexibility: "Souplesse négative",
    positive_flexibility: "Souplesse positive",
    other: "Autre",
    contract_new: "Nouveau contrat avec le même client (hors renouvellement)",
    contract_new_with_different_client: "Nouveau contrat avec un autre client",
    end_of_mission_by_client: "FDM à l’initiative du client",
    will_no_longer_work_with_us: "Ne travaillera plus avec nous (comportement, problème administratif…)",
    end_of_trial_period: "Fin de période d’essai",
  },
};

export const translatePlanningStatus: {
  [key in Locale]: { [key in PlanningStatus]: string };
} = {
  fr: {
    upcoming: "À venir",
    current: "En cours",
    late: "En retard",
    done: "Terminé",
    almost_done: "Fin cette semaine",
    upcoming_next_week: "À venir semaine prochaine",
    upcoming_this_week: "À venir cette semaine",
  },
};

export const translateTimesheetWeek = (status: TimesheetWeekStatus) => {
  switch (status) {
    case "fully_approved":
      return "Signé";
    case "completed_by_talent":
      return "En attente de signature client";
    case "not_completed":
      return "Non complété";
    case "rejected_by_client":
      return "Modifié par le client";
    default:
      return "Should not happen";
  }
};

export const translatePlanningAction = (action: PlanningAction) => {
  switch (action) {
    case "contract_details":
      return "👀 Détails du contrat";
    case "definitive_end":
      return "👋 Fin définitive";
    case "mark_as_signed":
      return "✍️ Marquer comme signé";
    case "create_amendment":
      return "👉 Créer un avenant";
  }
};

export const translateTalentQualificationStatus: {
  [key in Locale]: { [key in TalentQualificationStatus]: string };
} = {
  fr: {
    not_started: "Nouveau",
    in_progress: "En cours",
    completed: "Qualifié",
    disqualified: "Disqualifié",
    blacklisted: "Blacklisté",
  },
};

export const translateCommercialConditionStatus: {
  [key in Locale]: { [key in CommercialConditionStatus]: string };
} = {
  fr: {
    not_signed: "À envoyer",
    to_validate: "À valider",
    signed: "Signé",
    refused: "Refusé",
    canceled: "Annulé",
  },
};

export const translateMissionStatus: {
  [key in Locale]: { [key in MissionStatus]: string };
} = {
  fr: {
    monitoring: "Veille",
    active: "Ouverte",
    inactive: "Inactive",
    completed: "Pourvue",
    canceled: "Archivée",
    completed_by_client: "Pourvue par le client",
    completed_by_concurrence: "Pourvue par la concurrence",
  },
};

export const translateCompanyInsuranceRequestStatus: {
  [key in Locale]: { [key in CompanyInsuranceRequestStatus]: string };
} = {
  fr: {
    new: "Nouvelle",
    sent: "Envoyée",
    approved: "Approuvée",
    refused: "Refusée",
    terminated: "Résiliée",
    approved_temporarily: "Approuvée temporairement",
    approved_but_only_contract_minimum: "Approuvée mais seulement le minimum du contrat",
    approved_but_maximum_reached: "Requête approuvée mais le maximum a été atteint",
  },
};

export const translateCompanyInsuranceRequestOption: {
  [key in Locale]: { [key in CompanyInsuranceRequestPossibleAction]: string };
} = {
  fr: {
    send: "📩 Marquer la demande comme envoyée",
    approve: "✅ Approuver la demande",
    refuse: "❌ Refuser la demande",
  },
};

export const translateDefinitiveEndStatus: {
  [key in Locale]: { [key in DefinitiveEndStatus]: string };
} = {
  fr: {
    all: "Tous",
    to_process: "À traiter",
    done: "Traité",
  },
};

export const translateAcquisitionSources: {
  [key in Locale]: { [key in AcquisitionSource]: string };
} = {
  fr: {
    website: "Site internet",
    prospecting: "Prospection",
    event: "Événement",
    other: "Autre",
    apec__multidiffusion: "Apec (Multidiffusion)",
    apec__sourcing: "Apec (Sourcing)",
    beetween__import: "Beetween (Import)",
    cadremploi__multidiffusion: "Cadremploi (Multidiffusion)",
    direct_marketing__email: "Marketing Direct (Email)",
    direct_marketing__push: "Marketing Direct (Push)",
    direct_marketing__sms: "Marketing Direct (Sms)",
    direct_marketing__whatsapp: "Marketing Direct (Whatsapp)",
    emploi_interim__multidiffusion: "Emploi Interim (Multidiffusion)",
    facebook_ads__carousel: "Pub Facebook (Carousel)",
    facebook_ads__image: "Pub Facebook (Image)",
    facebook_ads__lead: "Pub Facebook (Lead)",
    facebook_ads__video: "Pub Facebook (Vidéo)",
    figaro_emploi__multidiffusion: "Figaro Emploi (Multidiffusion)",
    google_ads__display: "Google Ads (Display)",
    google_ads__search: "Google Ads (Search)",
    google_ads__video: "Google Ads (Vidéo)",
    hellowork__multidiffusion: "Hellowork (Multidiffusion)",
    hellowork__researcher: "Hellowork (Scrapping Bot)",
    hellowork__sourcing: "Hellowork (Sourcing)",
    hubspot__import: "Hubspot (Import)",
    indeed__multidiffusion: "Indeed (Multidiffusion)",
    inrecruiting__import: "Inrecruiting (Import)",
    linkedin__sourcing: "Linkedin (Sourcing)",
    linkedin_ads__carousel: "LinkedIn Ads (Carousel)",
    linkedin_ads__image: "LinkedIn Ads (Image)",
    linkedin_ads__lead: "LinkedIn Ads (Lead)",
    linkedin_ads__text: "LinkedIn Ads (Texte)",
    linkedin_ads__video: "LinkedIn Ads (Vidéo)",
    meteojob__multidiffusion: "Meteojob (Multidiffusion)",
    meteojob__researcher: "Meteojob (Scrapping Bot)",
    ordre_des_architectes__multidiffusion: "Ordre Des Architectes (Multidiffusion)",
    pole_emploi__multidiffusion: "France Travail (Multidiffusion)",
    pole_emploi__researcher: "France Travail (Scrapping Bot)",
    pole_emploi__sourcing: "France Travail (Sourcing)",
    social_media__facebook: "Compte Facebook asap.work",
    social_media__instagram: "Compte Instagram asap.work",
    social_media__linkedin: "Compte Linkedin asap.work",
    social_media__tiktok: "Compte Tiktok asap.work",
    social_media__youtube: "Compte Youtube asap.work",
    sponsoring: "Sponsoring",
    co_marketing: "Co-Marketing",
    newsletter: "Newsletter",
    referral: "Recommandation",
    emailing: "Campagne Email",
    cvaden__sourcing: "Cvaden (Sourcing)",
    meteojob__sourcing: "Meteojob (Sourcing)",
    linkedin__multidiffusion: "Linkedin (Multidiffusion)",
    directemploi__multidiffusion: "Directemploi (Multidiffusion)",
    other__multidiffusion: "Autre (Multidiffusion)",
    facebook__sourcing: "Facebook (Sourcing)",
    facebook__marketplace: "Facebook (Marketplace)",
    facebook__multidiffusion: "Facebook (Multidiffusion)",
  },
};

export const translateApiCaller: {
  [key in Locale]: { [key in ApiCaller]: string };
} = {
  fr: {
    app__back_office: "Backoffice",
    app__researcher: "Robot chercheur",
    app__talent: "App mobile",
    legacy__xano: "Xano",
    make__facebook_ads__lead: "Facebook Ads",
    make__multidiffusion__broadbeen: "Broadbeen",
    make__multidiffusion__talentplug: "Talentplug",
    make__website__form: "Site web",
    sync__inrecruiting: "Inrecruiting",
  },
};

export const translateExpenseClaimRequestType: {
  [key in Locale]: { [key in ExpenseClaimRequestType]: string };
} = {
  fr: {
    ppe: "EPI",
    transportation: "Transports",
    mileage: "Kilométrage",
    tooling: "Outillage",
    food: "Repas",
    accommodation: "Hébergement",
    travel: "Déplacements",
  },
};

export const translateNullExpenseClaimRequestType: {
  [key in Locale]: string;
} = {
  fr: "Non Renseigné",
};

export const translatePpeRequestStatus: {
  [key in Locale]: { [key in PpeRequestStatus]: string };
} = {
  fr: {
    todo: "À traiter",
    approved: "Approuvé",
    pending: "En cours",
    refused: "Refusé",
  },
};

export const translateContractDocumentType: {
  [key in Locale]: { [key in ContractDocumentType]: string };
} = {
  fr: {
    pre_employment_declaration: "Déclaration préalable à l'embauche",
    contract_client: "Contrat client non signé",
    contract_talent: "Contrat talent non signé",
    contract_client_signed: "Contrat client signé",
    contract_talent_signed: "Contrat talent signé",
    other: "Autre",
  },
};

export const translateAppRoleForOnboarding: {
  [key in Locale]: { [key in AppRole]: string };
} = {
  fr: {
    team_member: "Membre",
    consultant_team_member: "Ops",
    accounting_team_member: "Admin",
    csm_team_member: "WSM",
    talent: "Talent",
    team_member_developer: "Tech",
    team_member_manager: "Manager",
    team_member_marketing: "Marketing",
    super_team_member_deprecated: "-",
  },
};

export const translateAppRole: {
  [key in Locale]: { [key in AppRole]: string };
} = {
  fr: {
    accounting_team_member: "Compta",
    consultant_team_member: "Consultant",
    csm_team_member: "WSM",
    super_team_member_deprecated: "Super (déprécié)",
    talent: "Talent",
    team_member: "Membre",
    team_member_developer: "Dev",
    team_member_manager: "Manager",
    team_member_marketing: "Marketing",
  },
};

export const translateOnboardingRequestStatus: {
  [key in Locale]: { [key in OnboardingRequestStatus]: string };
} = {
  fr: {
    todo: "À traiter",
    done: "Terminé",
    canceled: "Annulé",
    pending: "En attente",
  },
};

export const translateOnboardingStatus: {
  [key in Locale]: { [key in OnboardingStatus]: string };
} = {
  fr: {
    todo: "À traiter",
    done: "Terminé",
    canceled: "Annulé",
    pending: "En attente",
    request_to_submit: "Demande à réaliser",
  },
};

export const translateResidencePermitStatus: {
  [key in Locale]: { [key in ResidencePermitStatus]: string };
} = {
  fr: {
    valid: "Valide",
    expired: "Expiré",
    expired_soon: "Expire bientôt",
    expiration_undefined: "Date d'expiration non définie",
    document_to_upload: "Document manquant",
    expired_receipt: "Récépissé expiré",
    expired_soon_receipt: "Récépissé expire bientôt",
    valid_receipt: "Récépissé valide",
  },
};

export const translateRecommandationLevels: {
  [key in Locale]: { [key in RecommandationLevel]: string };
} = {
  fr: {
    highly_recommended: "🌟 Très bien",
    recommended: "👍 Bien",
    no_opinion: "😐 Moyennement bien",
    not_recommended: "👎 Pas bien",
    highly_not_recommended: "👎👎 Pas bien du tout",
  },
};

export const translateRecommandationLevelsInStars: {
  [key in Locale]: { [key in RecommandationLevel]: string };
} = {
  fr: {
    highly_recommended: "⭐️⭐️⭐️⭐️⭐️",
    recommended: "⭐️⭐️⭐️⭐️",
    no_opinion: "⭐️⭐️⭐️",
    not_recommended: "⭐️⭐️",
    highly_not_recommended: "⭐️",
  },
};

export const translateAvailabilityStatus: {
  [key in Locale]: { [key in TalentAvailabilityStatus]: string };
} = {
  fr: {
    available_now: "🟢 Disponible maintenant",
    available_soon: "🟠 Disponible bientôt",
    not_available: "🔴 Pas disponible",
  },
};

export const translateWorkAccidentRequestStatus: {
  [key in Locale]: { [key in WorkAccidentRequestStatus]: string };
} = {
  fr: {
    todo: "À traiter",
    pending: "En cours",
    done: "Terminé",
    canceled: "Annulé",
  },
};
